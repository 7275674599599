import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumb from '../../Components/Breadcrumb';
import * as dataJSON from "../../Data/Version.json";
import Meta from "../../Components/Meta";
import { useFormik } from "formik";
import axios from 'axios';
import "./Version.scss";
import HeaderAccount from "../../Components/HeaderAccount/HeaderAccount";
import SideBar from '../../Components/SideBar/SideBar';
import Footer from '../../Components/Footer';
import BigTitle from "../../Components/BigTitle";
import ReactLoading from 'react-loading';

const MAX_RETRIES = 5; // Nombre maximum de tentatives
const INITIAL_DELAY = 1000; // Délai initial en millisecondes (1 seconde)

const fetchDataWithRetry = async (url, retries = MAX_RETRIES, delay = INITIAL_DELAY) => {
  try {
    // Essaye d'exécuter la requête
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Erreur lors de la requête (tentative restante: ${retries}):`, error);

    if (retries === 0) {
      // Si on atteint 0 tentatives restantes, on jette l'erreur
      throw new Error('Echec après plusieurs tentatives.');
    }

    // Sinon, on attend un certain temps et on réessaie
    await new Promise(resolve => setTimeout(resolve, delay));

    // Augmenter le délai de manière exponentielle
    return fetchDataWithRetry(url, retries - 1, delay * 2);
  }
};

export const Version = (props) => {
    const { lang } = useParams();
    const [data, setData] = useState(dataJSON.default[lang]);
    const [meta, setMeta] = useState({
        title: data.meta.title,
        description: data.meta.description,
        canonical: data.meta.canonical,
        image: data.meta.image,
        meta: {
            charset: "utf-8",
            name: {
                keywords: data.meta.keywords,
            }
        },
    });

    const [versionPhysio, setVersionPhysio] = useState(null);
    const [versionPro, setVersionPro] = useState(null);
    const [error, setError] = useState(null);

    let auth = JSON.parse(sessionStorage.getItem('userData'));

    const user_id = auth.user_id;

    useEffect(() => {
        const loadVersions = async () => {
          try {
            const physioData = await fetchDataWithRetry('/Back-end/getVersion/PHYSIO');
            const proData = await fetchDataWithRetry('/Back-end/getVersion/PRO');
            
            setVersionPhysio(physioData);
            setVersionPro(proData);
          } catch (err) {
            setError(err.message);
          }
        };
    
        loadVersions();
    }, [user_id]); // Dépendance sur user_id pour n'exécuter qu'une fois

    useEffect(() => {
        setData(dataJSON.default[lang]);
        setMeta({
            title: dataJSON.default[lang].meta.title,
            description: dataJSON.default[lang].meta.description,
            canonical: dataJSON.default[lang].meta.canonical,
            keywords: dataJSON.default[lang].meta.keywords,
            image: dataJSON.default[lang].meta.image
        });
    }, [lang]);

    const notification = {
        sendUpdateNotification(updateInfo, data) {
            axios.post("/Back-end/getMailNotificationUpdate", updateInfo)
            .then((response) => {
                // console.log(response);
                response.data.forEach(device => {
                    const params = {
                        device: device,
                        mail: data.mail
                    };
                    // console.log(params);
                    axios.post("/Back-end/sendNotificationUpdate", params)
                    .then((response) => {
                        // console.log(response.data);
                    })
                    .catch(console.error);
                });

                alert("Envoi des notifications effectuées !");
                window.location.reload();
            }).catch(console.error);
        }
    };

    const handleSubmit = (dataVersion) => {
        axios.post("/Back-end/updateSoftVersion", dataVersion)
            .then((response) => {
                console.log(response.data);
                notification.sendUpdateNotification(dataVersion, data);
            })
            .catch(console.error);
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    if (!versionPhysio || !versionPro) {
        return <ReactLoading type="bars" color="#15acf2" height={667} width={375} />;
    }

    return (
        <>
            <div className='formCreate'>
                <Meta meta={meta} />
                <HeaderAccount />
                <Breadcrumb title={data.breadcrumb} />
                <SideBar>
                    <VersionForm
                        versionPhysio={versionPhysio}
                        versionPro={versionPro}
                        data={data}
                        notification={notification}
                        handleSubmit={handleSubmit}
                    />
                </SideBar>
            </div>
            <Footer />
        </>
    );
};

const VersionForm = ({ versionPhysio, versionPro, data, notification, handleSubmit }) => {

    const mySchema = Yup.object().shape({
        model: Yup.string().required(data.update.errMsg),
        type: Yup.string().required(data.update.errMsg),
        value: Yup.string().required(data.update.errMsg),
        info: Yup.string().required(data.update.errMsg),
    });

    const formik = useFormik({
        initialValues: {
            model: '', // PHYSIO ou PRO
            type: '', // Core ou Interface
            value: '',
            info: ''
        },
        validationSchema: mySchema,
        onSubmit: (values) => {
            const dataVersion = {
                model: values.model.toUpperCase(),
                type: values.type.toUpperCase(),
                value: values.value,
                desc: values.info
            };
            handleSubmit(dataVersion);
        },
        enableReinitialize: true,
    });

    return (
        <div>
            <section className='displayVersion'>
                <BigTitle title={data.info.title} direct="left" />
                <div className="form-group mb-3">
                    <label>{data.info.physio}</label>
                    <div>
                        <label>{data.info.interface} {versionPhysio.ihm}</label>
                    </div>
                    <div>
                        <label>{data.info.core} {versionPhysio.core}</label>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label>{data.info.pro}</label>
                    <div>
                        <label>{data.info.interface} {versionPro.ihm}</label>
                    </div>
                    <div>
                        <label>{data.info.core} {versionPro.core}</label>
                    </div>
                </div>
            </section>
            <section className='displayVersion'>
                <BigTitle title={data.update.title} direct="left" />

                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-3">
                        <label>{data.update.model}</label>
                        {/* <div> */}
                            <label>
                                <input
                                    type="radio"
                                    name="model"
                                    value="PHYSIO"
                                    checked={formik.values.model === 'PHYSIO'}
                                    onChange={formik.handleChange}
                                />
                                PHYSIO
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="model"
                                    value="PRO"
                                    checked={formik.values.model === 'PRO'}
                                    onChange={formik.handleChange}
                                />
                                PRO
                            </label>
                        {/* </div> */}
                        {formik.errors.model && <div className="error">{formik.errors.model}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label>{data.update.type}</label>
                        {/* <div> */}
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="Core"
                                    checked={formik.values.type === 'Core'}
                                    onChange={formik.handleChange}
                                />
                                Coeur
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="Ihm"
                                    checked={formik.values.type === 'Ihm'}
                                    onChange={formik.handleChange}
                                />
                                Interface
                            </label>
                        {/* </div> */}
                        {formik.errors.type && <div className="error">{formik.errors.type}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label>{data.update.value}</label>
                        <input
                            type="text"
                            name="value"
                            value={formik.values.value}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors.value && <div className="error">{formik.errors.value}</div>}
                    </div>

                    <div className="form-group textarea-group mb-3">
                        <label>{data.update.description}</label>
                        <textarea
                            name="info"
                            value={formik.values.info}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.errors.info && <div className="error">{formik.errors.info}</div>}
                    </div>

                    <div className="form-group d-flex justify-content-end gap-3">
                        <button type="submit" className="btn colorBtn" disabled={formik.isSubmitting || !formik.isValid}>
                            {data.update.btnAdd}
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default Version